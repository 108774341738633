import SendReviewMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Event/SendReviewMethodInterface';
import EventReviewRequestModel from '@/shared/Api/Model/RPC/Event/EventReviewRequestModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';

class SendReviewMethod implements SendReviewMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  call(review: EventReviewRequestModel): Promise<void> {
    return this.client.instance.post(
      '/v2/event/sendReview',
      review,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}

export default SendReviewMethod;
