import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import GetAvailableCoinsMethodInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/GetAvailableCoinsMethodInterface';
import GetAvailableCoinsMethodResponseInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/GetAvailableCoinsMethodResponseInterface';

class GetAvailableCoinsMethod implements GetAvailableCoinsMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  call(): Promise<GetAvailableCoinsMethodResponseInterface> {
    return this.client.instance.post<GetAvailableCoinsMethodResponseInterface>('/v2/lottery/getAvailableCoins')
      .then((response) => response.data);
  }
}

export default GetAvailableCoinsMethod;
