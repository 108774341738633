import SystemConfigNameEnum from '@/entities/SystemConfig/Enum/SystemConfigNameEnum';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import SystemConfigServiceInterface from '@/app/Services/SystemConfig/Contract/SystemConfigServiceInterface';
import SystemConfigInterface from '@/entities/SystemConfig/Model/SystemConfigInterface';

class SystemConfigService implements SystemConfigServiceInterface {
  private readonly serviceContainer: ServiceContainerInterface;

  private systemConfig?: SystemConfigInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  isLotteryCoinPurchaseAvailable(): boolean {
    if (!this.systemConfig) {
      throw new Error('Run updateConfig method first.');
    }

    return this.systemConfig[SystemConfigNameEnum.LOTTERY_COIN_PURCHASE_AVAILABLE];
  }

  async updateConfig(): Promise<void> {
    const {
      apiService,
    } = this.serviceContainer;

    this.systemConfig = await apiService.rpc.systemConfig.getConfig.call();
  }
}

export default SystemConfigService;
