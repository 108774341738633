import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserServiceInterface from '@/app/Services/UserService/Contract/UserServiceInterface';
import UserModel from '@/entities/User/UserModel';

class UserService implements UserServiceInterface {
  private readonly serviceContainer: ServiceContainerInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  listClubTop(): Promise<Array<UserModel>> {
    const {
      apiService,
    } = this.serviceContainer;

    return apiService.rpc.user.listClubTop.call();
  }
}

export default UserService;
