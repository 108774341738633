import SendReviewMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/SendReviewMethodInterface';
import TournamentReviewRequestModel
  from '@/shared/Api/Model/RPC/Tournament/TournamentReviewRequestModel';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';

class SendReviewMethod implements SendReviewMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  call(
    payload: { tournamentReview: TournamentReviewRequestModel, tournament: TournamentModel },
  ): Promise<void> {
    return this.client.instance.post(
      '/v2/tournament/send-review',
      {
        ...payload,
        file: payload.tournamentReview.file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}

export default SendReviewMethod;
