import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ListCurrentMethodInterface from '@/shared/Api/Contract/Api/RPC/Tournament/ListCurrentMethodInterface';
import type TournamentModel from '@/entities/Tournament/TournamentModel';

class ListAvailableForRegistrationMethod implements ListCurrentMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<TournamentModel[]> {
    return this.client.instance.post<TournamentModel[]>('/v2/tournament/list/available-for-registration')
      .then((response) => response.data);
  }
}

export default ListAvailableForRegistrationMethod;
