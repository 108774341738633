import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-452ffcaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "tournament-widget" }
const _hoisted_3 = { class: "tournament-widget__buttons-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TournamentStatisticsModal = _resolveComponent("TournamentStatisticsModal")!
  const _component_ErrorModalWidget = _resolveComponent("ErrorModalWidget")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.tournamentStatistics)
      ? (_openBlock(), _createBlock(_component_TournamentStatisticsModal, {
          key: 0,
          tournamentStatistics: _ctx.state.tournamentStatistics,
          onClose: _ctx.closeStatistics
        }, null, 8, ["tournamentStatistics", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.state.showErrorModal)
      ? (_openBlock(), _createBlock(_component_ErrorModalWidget, {
          key: 1,
          show: _ctx.state.showErrorModal,
          onClose: _ctx.handleErrorModalClose
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Вы уже зарегистрированы! ")
          ]),
          _: 1
        }, 8, ["show", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_modal, {
      open: _ctx.state.rulesModalShow,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.rulesModalShow) = $event)),
      centered: true,
      closable: false,
      title: "Правила турнира",
      onCancel: _ctx.closeRulesModal
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          block: "",
          onClick: _ctx.closeRulesModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Закрыть ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createElementVNode("span", { innerHTML: _ctx.rulesModalText }, null, 8, _hoisted_1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open", "onCancel"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_text, { strong: true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.tournament.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, { copyable: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.tournament.hash), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" Дата старта: " + _toDisplayString(_ctx.getTournamentStartDate(_ctx.tournament)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" Дата финиша: " + _toDisplayString(_ctx.getTournamentEndDate(_ctx.tournament)), 1)
        ]),
        _: 1
      }),
      (_ctx.active)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            block: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleRegisterClick(_ctx.tournament)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Зарегистрироваться ")
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_button, {
                block: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openRulesModal(_ctx.tournament)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Правила турнира ")
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                block: "",
                icon: _ctx.h(_ctx.ApartmentOutlined),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openTournamentStatistics(_ctx.tournament)))
              }, null, 8, ["icon"])
            ]),
            _createVNode(_component_a_button, {
              block: "",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goToTournamentChat(_ctx.tournament)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Чат турнира ")
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              block: "",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToTeamChat(_ctx.tournament)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Чат сборной ")
              ]),
              _: 1
            })
          ], 64))
    ])
  ], 64))
}