<template>
  <div>
    <ErrorModalWidget
      v-if="state.showErrorModal"
      :show="state.showErrorModal"
      @close="handleModalClose"
    >
      Вы уже зарегистрированы!
    </ErrorModalWidget>
    <ReviewModal
      v-if="state.showReviewModal"
      :show="state.showReviewModal"
      :challenge-template-list="state.eventList"
      :tournament-list="state.tournamentList"
      @applyTournament="handleApplyReview"
      @applyEvent="handleApplyReview"
      @close="handleCloseReview"
    />
    <div
      class="event-widget__buttons"
    >
      <template
        v-if="state.tournamentList.length > 0"
      >
        <TournamentWidget
          v-for="tournament in state.tournamentList"
          :key="`activeTournament${tournament.id}`"
          :tournament="tournament"
          :active="false"
        />
        <hr
          :style="{ width: '100%', opacity: 0.3 }"
        />
      </template>
      <template
        v-if="state.availableForRegisterTournamentList.length > 0"
      >
        <TournamentWidget
          v-for="tournament in state.availableForRegisterTournamentList"
          :key="`availableForRegistrationTournament${tournament.id}`"
          :tournament="tournament"
          :active="true"
        />
      </template>
      <template
        v-if="isEventsExists"
      >
        <hr
          v-if="state.eventList.length > 0 || state.tournamentList.length > 0"
          :style="{ width: '100%', opacity: 0.3 }"
        />
        <template
          v-for="challengeTemplate in filteredEventList"
        >
          <a-button
            v-if="!challengeTemplate.hide"
            :key="challengeTemplate.id"
            @click="handleEventClick(challengeTemplate)"
          >
            {{ challengeTemplate.name }}
          </a-button>
        </template>
        <template
          v-if="state.eventList.length > 0 || state.tournamentList.length > 0"
        >
          <a-divider
            v-if="filteredEventList.length > 0"
          />
          <a-button
            @click="handleOpenReviewModal()"
          >
            Написать отзыв
            <LikeOutlined />
          </a-button>
        </template>
      </template>
      <a-typography-text
        v-else
      >
        Скоро здесь откроется регистрация на событие
      </a-typography-text>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import ChallengeTemplateModel from '@/shared/Api/Model/RPC/Event/ChallengeTemplateModel';
import {
  useRouter,
} from 'vue-router';
import {
  LikeOutlined,
} from '@ant-design/icons-vue';
import ReviewModal from '@/widgets/EventWidget/Components/ReviewModal.vue';
import ErrorModalWidget from '@/widgets/ErrorModalWidget/ErrorModalWidget.vue';
import EventReviewRequestModel from '@/shared/Api/Model/RPC/Event/EventReviewRequestModel';
import {
  notification,
} from 'ant-design-vue';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import TournamentWidget from '@/widgets/EventWidget/Components/TournamentWidget.vue';

interface State {
  showErrorModal: boolean;
  showReviewModal: boolean;
  eventList: ChallengeTemplateModel[];
  availableForRegisterTournamentList: TournamentModel[];
  tournamentList: TournamentModel[];
}

export default defineComponent({
  name: 'EventWidget',

  components: {
    TournamentWidget,
    ReviewModal,
    ErrorModalWidget,
    LikeOutlined,
  },

  setup() {
    const state: State = reactive<State>({
      showErrorModal: false,
      showReviewModal: false,
      eventList: [],
      availableForRegisterTournamentList: [],
      tournamentList: [],
    });

    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    const router = useRouter();

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    function handleEventClick(challengeTemplate: ChallengeTemplateModel): void {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if ((challengeTemplate.challenges ?? []).length === 0) {
        serviceContainer.store.dispatch('addChallengeTemplate', challengeTemplate);

        router.push({ name: 'eventChallenge' });

        return;
      }

      state.showErrorModal = true;
    }

    function handleModalClose() {
      state.showErrorModal = false;
    }

    async function handleApplyReview(review: EventReviewRequestModel) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      try {
        await serviceContainer.apiService.rpc.event.sendReview.call(review);

        notification.success({
          message: 'Отзыв успешно отправлен!',
          description: 'Спасибо! После проверки отзыв будет опубликован в Главном чате!',
          duration: 30,
        });
      } catch (error) {
        notification.error({
          message: 'Улыбка!',
          description: 'Отчет не был отправлен! Повторите попытку позже.',
          duration: 30,
        });
      }

      state.showReviewModal = false;
    }

    async function handleApplyTournament(review: EventReviewRequestModel) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if (!review.tournament) {
        return;
      }

      const payload = {
        tournamentReview: {
          about: review.about,
          result: review.result,
          emotions: review.emotions,
          file: review.file,
        },
        tournament: review.tournament,
      };

      try {
        await serviceContainer.apiService.rpc.tournament.sendReview.call(payload);

        notification.success({
          message: 'Отзыв успешно отправлен!',
          description: 'Спасибо! После проверки отзыв будет опубликован в Главном чате!',
          duration: 30,
        });
      } catch (error) {
        notification.error({
          message: 'Улыбка!',
          description: 'Отчет не был отправлен! Повторите попытку позже.',
          duration: 30,
        });
      }

      state.showReviewModal = false;
    }

    function handleOpenReviewModal() {
      state.showReviewModal = true;
    }

    function handleCloseReview() {
      state.showReviewModal = false;
    }

    async function updateEventList() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      state.eventList = await serviceContainer.apiService.rpc.event.listActual.call();
    }

    async function updateAvailableForRegisterTournamentList() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      state.availableForRegisterTournamentList = await serviceContainer.apiService.rpc.tournament
        .listAvailableForRegistration
        .call();
    }

    async function updateTournamentList() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      state.tournamentList = await serviceContainer.apiService.rpc.tournament
        .listCurrent
        .call();
    }

    let eventUpdateInterval: number;

    onMounted(async () => {
      await updateEventList();
      await updateAvailableForRegisterTournamentList();
      await updateTournamentList();

      eventUpdateInterval = setInterval(
        () => {
          updateEventList();
          updateAvailableForRegisterTournamentList();
          updateTournamentList();
        },
        5000,
      );
    });

    onUnmounted(() => {
      clearInterval(eventUpdateInterval);
    });

    const filteredEventList = computed(
      () => state.eventList.filter((event) => !event.hide),
    );

    const isEventsExists = computed(() => {
      if (state.eventList.length > 0) {
        return true;
      }

      if (state.tournamentList.length > 0) {
        return true;
      }

      if (state.availableForRegisterTournamentList.length > 0) {
        return true;
      }

      return false;
    });

    return {
      state,
      filteredEventList,
      handleEventClick,
      handleModalClose,
      handleApplyReview,
      handleApplyTournament,
      handleCloseReview,
      handleOpenReviewModal,
      isEventsExists,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.event-widget {
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
