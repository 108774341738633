import type AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import type ChallengeTypeEnum from '@/entities/ChallengeTypeEnum';
import type GetNumberOfReportMapInterface from '@/shared/Api/Contract/Api/RPC/Challenge/GetNumberOfReportMapInterface';
import type NumberOfReportModel from '@/shared/Api/Model/RPC/Challenge/NumberOfReportModel';

class GetNumberOfReportMap implements GetNumberOfReportMapInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<Record<ChallengeTypeEnum, NumberOfReportModel>> {
    return this.client.instance.post<Record<ChallengeTypeEnum, NumberOfReportModel>>(
      '/v2/challenge/getNumberOfReportsMap',
    )
      .then((response) => response.data);
  }
}

export default GetNumberOfReportMap;
