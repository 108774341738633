import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card__text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "card__list"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "card__input"
}
const _hoisted_6 = {
  key: 1,
  class: "card__input"
}
const _hoisted_7 = { class: "card__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    hoverable: "",
    class: "card"
  }, {
    cover: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeOption.textLines, (text, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `textLine${index}`
          }, [
            _createElementVNode("p", null, [
              (text.weight === 'bold')
                ? (_openBlock(), _createElementBlock("b", _hoisted_2, _toDisplayString(text.text), 1))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(text.text), 1)
                  ], 64))
            ]),
            (text.list)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                  _createTextVNode(_toDisplayString(text) + " ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text.list, (listItem, listIndex) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: `listItem${index}${listIndex}`
                    }, [
                      (text.weight === 'bold')
                        ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(listItem), 1))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(listItem), 1)
                          ], 64))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      (_ctx.activeOption.field && _ctx.activeOption.valueType === 'input')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_a_input, {
              maxLength: _ctx.activeOption.maxLength,
              value: _ctx.state.values[_ctx.activeOption.field],
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInputChange($event, _ctx.activeOption.field)))
            }, null, 8, ["maxLength", "value"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.activeOption.field && _ctx.activeOption.valueType === 'select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.activeOption.field === 'topMember' && _ctx.topUserSelectOptions && _ctx.topUserSelectOptions.length > 0)
              ? (_openBlock(), _createBlock(_component_a_select, {
                  key: 0,
                  "model-value": _ctx.getSelectValue(_ctx.activeOption.field),
                  options: _ctx.topUserSelectOptions,
                  style: {"width":"100%"},
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectChange($event, _ctx.activeOption.field)))
                }, null, 8, ["model-value", "options"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.activeOption.buttonText)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "primary",
              block: "",
              disabled: _ctx.isForwardDisabled,
              onClick: _ctx.handleButtonClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.activeOption.buttonText), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showApplyButton)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 1,
              type: "primary",
              block: "",
              disabled: _ctx.isApplyDisabled,
              onClick: _ctx.handleApplyButton
            }, {
              default: _withCtx(() => [
                _createTextVNode(" ДАЛЬШЕ! ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}