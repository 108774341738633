import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import type TournamentModel from '@/entities/Tournament/TournamentModel';
import RegisterMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/RegisterMethodInterface';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';

class RegisterMethod implements RegisterMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(payload: { tournament: TournamentModel, challenge: ChallengeResponseModel }): Promise<void> {
    await this.client.instance.post<TournamentModel[]>(
      '/v2/tournament/register',
      payload,
    )
      .then((response) => response.data);
  }
}

export default RegisterMethod;
