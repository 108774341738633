<template>
  <div
    class="archive-challenges-widget"
  >
    <ArchiveChallenge
      v-for="challenge in challenges"
      :key="challenge.id"
      :challenge="challenge"
      @one-click-click="handleOneClickClick"
    />
  </div>
</template>

<script
  setup
  lang="ts"
>

import {
  defineProps,
  inject,
  withDefaults,
} from 'vue';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import ArchiveChallenge from '@/widgets/ArchiveChallengesWidget/ArchiveChallenge.vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';
import {
  useRouter,
} from 'vue-router';

interface Props {
  challenges: ChallengeResponseModel[];
}

const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

if (!serviceContainer) {
  throw new Error('serviceContainer not injected');
}

const router = useRouter();

withDefaults(
  defineProps<Props>(),
  {
    challenges: () => [],
  },
);

function saveChallenge(challenge: ChallengeResponseModel) {
  if (!serviceContainer) {
    throw new Error('serviceContainer not injected');
  }

  const challengeData = {
    description: challenge.description,
    reason: challenge.reason,
    bet: challenge.bet,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reportType: challenge.reportType?.name,
    numberOfReports: challenge.numberOfReports,
    userName: challenge.userName ?? serviceContainer.store.getters.getUser?.fullName,
    type: challenge.type,
  };

  serviceContainer.localStorageService.write(
    LocalStorageKeysEnum.CHALLENGE_SAVE_DATA,
    challengeData,
  );
}

function handleOneClickClick(challenge: ChallengeResponseModel) {
  saveChallenge(challenge);

  router.push({ name: 'challengeConfirm' });
}
</script>

<style
  scoped
  lang="scss"
>
.archive-challenges-widget {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
