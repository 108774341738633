import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ListMethodInterface from '@/shared/Api/Contract/Api/RPC/Tournament/ListMethodInterface';
import type TournamentModel from '@/entities/Tournament/TournamentModel';

class ListMethod implements ListMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<TournamentModel[]> {
    return this.client.instance.post<TournamentModel[]>('/v2/tournament/list')
      .then((response) => response.data);
  }
}

export default ListMethod;
