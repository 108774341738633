import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1153a72b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "treasure-island__block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_text, { strong: true }, {
      default: _withCtx(() => [
        _createTextVNode(" Как получить золотые жетоны? ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" 1. Зайди в раздел \"Позвать друга\", ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" 2. пригласи его по ссылке из этого раздела, ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" 3. затем нажми кнопку \"Бросить вызов\", введи хештег цели-челленджа своего друга и хештег своей цели-челленджа, ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" 4. выбери пункт \"Соперник впервые в YouFC\", ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(" 5. как только Друг выполнит свою цель-челлендж и получит первые 600 YOU, ты увидишь 20 золотых жетонов на своем счёте здесь. ")
      ]),
      _: 1
    }),
    _createVNode(_component_a_button, { onClick: _ctx.handleTreasureIslandClick }, {
      default: _withCtx(() => [
        _createTextVNode(" Смотреть сокровища YouFC ")
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.showBuyButton)
      ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          onClick: _ctx.handleBuyCoinClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Приз месяца ")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}