<template>
  <TournamentStatisticsModal
    v-if="state.tournamentStatistics"
    :tournamentStatistics="state.tournamentStatistics"
    @close="closeStatistics"
  />
  <ErrorModalWidget
    v-if="state.showErrorModal"
    :show="state.showErrorModal"
    @close="handleErrorModalClose"
  >
    Вы уже зарегистрированы!
  </ErrorModalWidget>
  <a-modal
    v-model:open="state.rulesModalShow"
    :centered="true"
    :closable="false"
    title="Правила турнира"
    @cancel="closeRulesModal"
  >
    <a-typography-text>
      <span
        v-html="rulesModalText"
      />
    </a-typography-text>

    <template
      #footer
    >
      <a-button
        block
        @click="closeRulesModal"
      >
        Закрыть
      </a-button>
    </template>
  </a-modal>
  <div
    class="tournament-widget"
  >
    <a-typography-text
      :strong="true"
    >
      {{ tournament.title }}
    </a-typography-text>
    <a-typography-text
      copyable
    >
      {{ tournament.hash }}
    </a-typography-text>
    <a-typography-text>
      Дата старта: {{ getTournamentStartDate(tournament) }}
    </a-typography-text>
    <a-typography-text>
      Дата финиша: {{ getTournamentEndDate(tournament) }}
    </a-typography-text>
    <a-button
      v-if="active"
      block
      @click="handleRegisterClick(tournament)"
    >
      Зарегистрироваться
    </a-button>
    <template
      v-else
    >
      <div
        class="tournament-widget__buttons-row"
      >
        <a-button
          block
          @click="openRulesModal(tournament)"
        >
          Правила турнира
        </a-button>
        <a-button
          block
          :icon="h(ApartmentOutlined)"
          @click="openTournamentStatistics(tournament)"
        />
      </div>
      <a-button
        block
        @click="goToTournamentChat(tournament)"
      >
        Чат турнира
      </a-button>
      <a-button
        block
        @click="goToTeamChat(tournament)"
      >
        Чат сборной
      </a-button>
    </template>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  h,
  inject,
  PropType,
  reactive,
} from 'vue';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import {
  TypographyText as ATypographyText,
} from 'ant-design-vue';
import moment from 'moment/moment';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';
import {
  useRouter,
} from 'vue-router';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import TournamentTeamMemberModel from '@/entities/Tournament/TournamentTeamMemberModel';
import {
  ApartmentOutlined,
} from '@ant-design/icons-vue';
import TournamentStatisticsModal
  from '@/widgets/EventWidget/Components/TournamentStatisticsModal.vue';
import TeamStatisticsModel from '@/shared/Api/Model/RPC/Tournament/TeamStatisticsModel';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ErrorModalWidget from '@/widgets/ErrorModalWidget/ErrorModalWidget.vue';

interface State {
  selectedTournamentId: number;
  rulesModalShow: boolean;
  showErrorModal: boolean;
  tournamentStatistics: TeamStatisticsModel[] | null;
}

export default defineComponent({
  methods: {
    ApartmentOutlined,
    h,
  },
  components: {
    ErrorModalWidget,
    TournamentStatisticsModal,
    ATypographyText,
  },
  props: {
    tournament: {
      type: Object as PropType<TournamentModel>,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<State>({
      selectedTournamentId: 0,
      rulesModalShow: false,
      showErrorModal: false,
      tournamentStatistics: null,
    });

    const router = useRouter();

    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const rulesModalText = computed(() => props.tournament.rules.replaceAll('\r\n', '<br>'));

    function getTournamentStartDate(tournament: TournamentModel) {
      return moment(tournament.startDate).format('DD.MM.YYYY');
    }

    function getTournamentEndDate(tournament: TournamentModel) {
      return moment(tournament.endDate).format('DD.MM.YYYY');
    }

    function handleRegisterClick(tournament: TournamentModel) {
      const userInfo: UserInfoResponse = serviceContainer?.store.getters.getUser;
      let userTournamentIds: Array<number | null> = [];

      if (userInfo) {
        const challenges = userInfo?.challenges ?? [];

        userTournamentIds = challenges.map((challenge) => challenge?.event?.tournament?.id ?? null);
      }

      if (userTournamentIds.includes(tournament.id)) {
        state.showErrorModal = true;

        return;
      }

      if (tournament.challengeTemplate) {
        serviceContainer?.localStorageService.write(LocalStorageKeysEnum.TOURNAMENT_SAVE_DATA, tournament);
        serviceContainer?.store.dispatch('addChallengeTemplate', tournament.challengeTemplate);

        router.push({ name: 'tournamentChallenge' });
      }
    }

    function handleErrorModalClose() {
      state.showErrorModal = false;
    }
    function closeRulesModal() {
      state.rulesModalShow = false;
    }

    function goToTournamentChat(tournament: TournamentModel): void {
      if (!tournament.mainChat?.link) {
        return;
      }

      window.open(tournament.mainChat.link, '_blank');
    }

    async function openTournamentStatistics(tournament: TournamentModel) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      const tournamentStatistics = await serviceContainer.apiService
        .rpc
        .tournament
        .getStatistics
        .call(tournament);

      if (tournamentStatistics) {
        state.tournamentStatistics = tournamentStatistics;
      }
    }

    function openRulesModal(): void {
      state.rulesModalShow = true;
    }

    function closeStatistics(): void {
      state.tournamentStatistics = null;
    }

    function goToTeamChat(tournament: TournamentModel): void {
      const user = serviceContainer?.store.getters.getUser;
      const filteredTeams = tournament.teams.filter(
        (team) => !!(team.members.find((member: TournamentTeamMemberModel) => member.user.id === user.id)),
      );

      if (filteredTeams.length === 0) {
        return;
      }

      const chatUrl = filteredTeams[0].chat?.link;

      if (!chatUrl) {
        return;
      }

      window.open(chatUrl, '_blank');
    }

    return {
      rulesModalText,
      state,
      getTournamentStartDate,
      getTournamentEndDate,
      handleRegisterClick,
      closeRulesModal,
      goToTournamentChat,
      openRulesModal,
      goToTeamChat,
      openTournamentStatistics,
      closeStatistics,
      handleErrorModalClose,
    };
  },
});

</script>

<style
  scoped
  lang="scss"
>
.tournament-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;

  &__buttons-row {
    display: flex;
    gap: 10px;
    width: 100%;
  }
}
</style>
