import { Module } from 'vuex';
import ChallengeTemplateModel from '@/app/Store/Model/ChallengeTemplateModel';
import ChallengeTemplateRPCModel from '@/shared/Api/Model/RPC/Event/ChallengeTemplateModel';

export const challengeTemplate: Module<ChallengeTemplateModel, unknown> = {
  state: {
    challengeTemplate: null,
  },

  getters: {
    getChallengeTemplate(state) {
      return state.challengeTemplate;
    },
  },

  mutations: {
    setChallengeTemplate(state, payload) {
      state.challengeTemplate = payload;
    },
  },

  actions: {
    addChallengeTemplate({ commit }, challengeTemplate: ChallengeTemplateRPCModel) {
      commit('setChallengeTemplate', challengeTemplate);
    },

    deleteChallengeTemplate({ commit }) {
      commit('setChallengeTemplate', null);
    },
  },
};
