<template>
  <a-card
    class="premium-widget"
  >
    <div
      class="premium-widget__wrapper"
    >
      <a-typography-text>
        {{ bannerText }}
      </a-typography-text>
      <a-button
        type="primary"
        @click.stop="goToPremium"
      >
        {{ buttonText }}
      </a-button>
    </div>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';
import {
  Button,
  Card,
  TypographyText,
} from 'ant-design-vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';

export default defineComponent({
  components: {
    AButton: Button,
    ACard: Card,
    ATypographyText: TypographyText,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const bannerText = computed(() => (props.user?.isPremium
      ? 'Ты — премиум-фрилайнер и получаешь больше!👑'
      : 'Премиум-фрилайнер получает больше!'));

    const buttonText = computed(() => (props.user?.isPremium
      ? 'Функции премиума'
      : 'Попробовать'));

    async function goToPremium() {
      if (!serviceContainer) {
        return;
      }

      const premiumLink = await serviceContainer.externalLinkService.getPremium();

      window.open(premiumLink);
    }

    return {
      bannerText,
      buttonText,
      goToPremium,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>

.premium-widget {
  margin: 0 auto 20px;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}
</style>
