import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import LotteryCoinTransactionModel from '@/entities/LotteryCoinTransaction/LotteryCoinTransactionModel';
import CreateMethodInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/CreateMethodInterface';

class CreateMethod implements CreateMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(lotteryCoinTransaction: LotteryCoinTransactionModel): Promise<LotteryCoinTransactionModel> {
    return this.client.instance.post<LotteryCoinTransactionModel>('/v2/lottery/createCoinsTransaction', lotteryCoinTransaction)
      .then((response) => response.data);
  }
}

export default CreateMethod;
