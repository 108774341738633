<template>
  <div
    class="team-statistics"
  >
    <a-typography-text
      :strong="true"
    >
      Сборная '{{ teamStatistics.teamName }}'
    </a-typography-text>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Выбор названия и капитана
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('teamCaptainAndTitleValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Без дисквалификации и предупреждений
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('disqualificationsValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Дополнительный Целленж
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('additionalChallengesValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Рассказ о ярком моменте
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('tournamentReviewValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Пожелание в команде
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('wishesValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Количество YOU
      </a-typography-text>
      <a-typography-text>
        {{ getValueString('ratingValue') }}
      </a-typography-text>
    </div>
    <div
      class="team-statistics__row"
    >
      <a-typography-text
        :strong="true"
      >
        Итого: {{ getValueString('total') }}
      </a-typography-text>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import {
  defineProps,
} from 'vue';
import TeamStatisticsModel from '@/shared/Api/Model/RPC/Tournament/TeamStatisticsModel';
import WordDeclensionHelper from '@/shared/Helpers/WordDeclensionHelper';

const props = defineProps<{ teamStatistics: TeamStatisticsModel }>();

function getValueString(key: 'teamCaptainAndTitleValue' | 'disqualificationsValue' | 'additionalChallengesValue' | 'tournamentReviewValue' | 'wishesValue' | 'ratingValue' | 'total'): string {
  const maxValuesMap = {
    teamCaptainAndTitleValue: 20,
    disqualificationsValue: 10,
    additionalChallengesValue: 20,
    tournamentReviewValue: 20,
    wishesValue: 10,
    ratingValue: 20,
    total: 100,
  };

  const value = props.teamStatistics.totalStatistics[key];

  const text = WordDeclensionHelper.getScoreDeclension(value);

  return `${value} ${text} из ${maxValuesMap[key]} возможных`;
}
</script>

<style scoped lang="scss">
.team-statistics {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__row {
    display: flex;
    justify-content: space-between;
  }
}
</style>
