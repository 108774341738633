import GetConfigMethodInterface from '@/shared/Api/Contract/Api/RPC/SystemConfig/GetConfigMethodInterface';
import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import SystemConfigInterface from '@/entities/SystemConfig/Model/SystemConfigInterface';

class GetConfigMethod implements GetConfigMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<SystemConfigInterface> {
    return this.client.instance.post<SystemConfigInterface>('/v2/system-config/getConfig')
      .then((response) => response.data);
  }
}

export default GetConfigMethod;
