<template>
  <div
    class="freeline-statistics-widget__text-block_column"
  >
    <div
      class="freeline-statistics-widget__statistics"
    >
      <a-typography-text>
        Всего турниров {{ tournamentsCount }}
      </a-typography-text>
      <a-typography-text>
        Всего выполненных целей-челленджей {{ challengesCount }}
      </a-typography-text>
    </div>
    <div
      v-if="archiveChallengeList.length > 0"
      class="freeline-statistics-widget__archive"
    >
      <a-typography-text
        :strong="true"
      >
        История Целленжей, чтобы повторить нажми <RedoOutlined />
      </a-typography-text>
      <ArchiveChallengesWidget
        :challenges="archiveChallengeList"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  inject,
  defineComponent,
  PropType, computed,
} from 'vue';
import {
  TypographyText,
} from 'ant-design-vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import ArchiveChallengesWidget from '@/widgets/ArchiveChallengesWidget/ArchiveChallengesWidget.vue';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import {
  RedoOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    ArchiveChallengesWidget,
    RedoOutlined,
    ATypographyText: TypographyText,
  },
  props: {
    user: {
      type: Object as PropType<UserInfoResponse | null>,
      required: true,
    },
    archiveChallengeList: {
      type: Array as PropType<ChallengeResponseModel[]>,
      default: () => [],
    },
  },
  setup(props) {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const tournamentsCount = computed(() => {
      if (!props.user) {
        return 0;
      }

      const challengesCount = props.user.challenges
        .filter((challenge) => challenge.success && !!challenge.event)
        .length;

      return props.user.tournaments.length + challengesCount;
    });

    const challengesCount = computed(() => {
      if (!props.user) {
        return 0;
      }

      return props.user.challenges
        .filter((challenge) => challenge.success && !challenge.event)
        .length;
    });

    return {
      tournamentsCount,
      challengesCount,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.freeline-statistics-widget {
  &__statistics {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__additional-info {
    padding-top: 5px;
    font-style: italic;

    &_super {
      vertical-align: super;
    }
  }

  &__archive {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__text-block {
    &_column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
