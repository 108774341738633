import LotteryCoinTransactionServiceInterface from '@/app/Services/LotteryCoinTransactionService/Contract/LotteryCoinTransactionServiceInterface';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import LotteryCoinTransactionModel from '@/entities/LotteryCoinTransaction/LotteryCoinTransactionModel';
import CalculateCoinsMethodResponseInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/CalculateCoinsMethodResponseInterface';
import GetAvailableCoinsMethodResponseInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/GetAvailableCoinsMethodResponseInterface';

class LotteryCoinTransactionService implements LotteryCoinTransactionServiceInterface {
  private readonly serviceContainer: ServiceContainerInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  create(lotteryCoinTransaction: LotteryCoinTransactionModel): Promise<LotteryCoinTransactionModel> {
    const {
      apiService,
    } = this.serviceContainer;

    return apiService.rpc.lotteryCoinTransaction.create.call(lotteryCoinTransaction);
  }

  calculateCoins(lotteryCoinTransaction: LotteryCoinTransactionModel): Promise<CalculateCoinsMethodResponseInterface> {
    const {
      apiService,
    } = this.serviceContainer;

    return apiService.rpc.lotteryCoinTransaction.calculateCoins.call(lotteryCoinTransaction);
  }

  getAvailableCoins(): Promise<GetAvailableCoinsMethodResponseInterface> {
    const {
      apiService,
    } = this.serviceContainer;

    return apiService.rpc.lotteryCoinTransaction.getAvailableCoins.call();
  }
}

export default LotteryCoinTransactionService;
