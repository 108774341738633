enum LocalStorageKeysEnum {
  TOKEN = 'authToken',
  REFRESH_TOKEN = 'refreshToken',
  CHALLENGE_SAVE_DATA = 'challengeSaveData',
  INVITATION_CODE = 'invitationCode',
  TOURNAMENT_SAVE_DATA = 'tournamentSaveData',
  LOTTERY_COIN_TRANSACTION_SAVE_DATA = 'lotteryCoinTransactionSaveData',
}

export default LocalStorageKeysEnum;
