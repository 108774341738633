import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import LotteryCoinTransactionModel from '@/entities/LotteryCoinTransaction/LotteryCoinTransactionModel';
import CalculateCoinsMethodInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/CalculateCoinsMethodInterface';
import CalculateCoinsMethodResponseInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/CalculateCoinsMethodResponseInterface';

class CalculateCoinsMethod implements CalculateCoinsMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(lotteryCoinTransaction: LotteryCoinTransactionModel): Promise<CalculateCoinsMethodResponseInterface> {
    return this.client.instance.post<CalculateCoinsMethodResponseInterface>('/v2/lottery/calculateCoins', lotteryCoinTransaction)
      .then((response) => response.data);
  }
}

export default CalculateCoinsMethod;
