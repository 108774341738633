<template>
  <a-modal
    :open="true"
    :centered="true"
    :closable="false"
    title="Статистика турнира"
    class="review-modal"
    @cancel="handle"
  >
    <template
      v-for="(teamStatistics, index) in tournamentStatistics"
      :key="`team-statistics${index}`"
    >
      <TeamStatistics
        :team-statistics="teamStatistics"
      />

      <a-divider
        v-if="index !== tournamentStatistics.length - 1"
      />
    </template>

    <template
      #footer
    >
      <a-button
        block
        @click="handle"
      >
        Закрыть
      </a-button>
    </template>
  </a-modal>
</template>

<script
  setup
  lang="ts"
>
import {
  defineEmits,
  defineProps,
  reactive,
} from 'vue';
import TeamStatisticsModel from '@/shared/Api/Model/RPC/Tournament/TeamStatisticsModel';
import TeamStatistics from '@/widgets/EventWidget/Components/TeamStatistics.vue';

interface State {
  loading: boolean;
}

const emit = defineEmits(['close']);

reactive<State>({
  loading: true,
});

function handle() {
  emit('close');
}

defineProps<{ tournamentStatistics: TeamStatisticsModel[] }>();
</script>
