import { Module } from 'vuex';
import invitationCodeModel from '@/app/Store/Model/invitationCodeModel';
import InvitationCodeRPCModel from '@/shared/Api/Model/RPC/InvitationCode/InvitationCodeModel';

export const invitationCode: Module<invitationCodeModel, unknown> = {
  state: {
    invitationCode: null,
  },

  getters: {
    getInvitationCode(state) {
      return state.invitationCode;
    },
  },

  mutations: {
    setInvitationCode(state, payload) {
      state.invitationCode = payload;
    },
  },

  actions: {
    addInvitationCode({ commit }, challengeTemplate: InvitationCodeRPCModel) {
      commit('setInvitationCode', challengeTemplate);
    },

    deleteInvitationCode({ commit }) {
      commit('setInvitationCode', null);
    },
  },
};
