import moment, { type Moment } from 'moment';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';

class ChallengeHelper {
  static changeChallengeHash(id: string): string {
    if (id.startsWith('#w') || id.startsWith('#od') || id.startsWith('#t')) {
      return id;
    }

    return id.replace('#', '#f');
  }

  static getDaysForFinish(challenge: ChallengeResponseModel): number {
    const endDate = this.getChallengeEndDate(challenge);

    const currentDayStart = moment().startOf('day');
    const diff = endDate.diff(currentDayStart);

    const days = Math.floor(moment.duration(diff).asDays());

    return days < 0 ? 0 : days;
  }

  static isChallengeActive(challenge: ChallengeResponseModel): boolean {
    if (!challenge?.event?.tournament && challenge.success) {
      return false;
    }

    // TODO: убрать на бэк
    if (!challenge.approved || challenge.deleted || challenge.failed || challenge.archived) {
      return false;
    }

    const currentDate = moment();

    const endDate = this.getChallengeEndDate(challenge);

    const awardsEndDate = this.getAwardsEndDate(challenge);

    if (awardsEndDate.diff(currentDate) <= 0) {
      return false;
    }

    if (endDate.diff(currentDate) > 0) {
      return true;
    }

    const reportsCount = challenge.reports?.length ?? 0;
    const numberOfReports = challenge.numberOfReports ?? 0;

    return reportsCount >= numberOfReports;
  }

  static getChallengeStartDate(challenge: ChallengeResponseModel): Moment {
    return moment(challenge.startDate);
  }

  static getChallengeEndDate(challenge: ChallengeResponseModel): Moment {
    return moment(challenge.endDate);
  }

  static getAwardsStartDate(challenge: ChallengeResponseModel): Moment {
    return moment(challenge.awardStartDate);
  }

  static getAwardsEndDate(challenge: ChallengeResponseModel): Moment {
    return moment(challenge.awardEndDate);
  }
}

export default ChallengeHelper;
