import ListClubTopMethodInterface from '@/shared/Api/Contract/Api/RPC/User/ListClubTopMethodInterface';
import AxiosClientServiceInterface from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import UserModel from '@/entities/User/UserModel';

class ListClubTopMethod implements ListClubTopMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<Array<UserModel>> {
    return this.client.instance.post<Array<UserModel>>('/v2/user/listClubTop')
      .then((response) => response.data);
  }
}

export default ListClubTopMethod;
