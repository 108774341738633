import StatisticsMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/StatisticsMethodInterface';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import TeamStatisticsModel from '@/shared/Api/Model/RPC/Tournament/TeamStatisticsModel';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';

class StatisticsMethod implements StatisticsMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  call(tournament: TournamentModel): Promise<TeamStatisticsModel[]> {
    return this.client.instance.post<TeamStatisticsModel[]>(
      '/v2/tournament/statistics',
      {
        tournament,
      },
    )
      .then((response) => response.data);
  }
}

export default StatisticsMethod;
