import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UtilsServiceInterface from '@/app/Services/UtilsService/Contract/UtilsServiceInterface';
import LocalStorageServiceInterface
  from '@/app/Services/LocalStorage/Contract/LocalStorageServiceInterface';
import AuthorizationServiceInterface
  from '@/app/Services/Authorization/Contract/AuthorizationServiceInterface';
import { Store } from 'vuex';
import ApiServiceInterface from '@/shared/Api/Contract/ApiServiceInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ApiService from '@/shared/Api/ApiService';
import AxiosClientService from '@/app/Services/AxiosClientService/AxiosClientService';
import UtilsService from '@/app/Services/UtilsService/UtilsService';
import { store } from '@/app/providers/store';
import LocalStorageService from '@/app/Services/LocalStorage/LocalStorageService';
import AuthorizationService from '@/app/Services/Authorization/AuthorizationService';
import ExternalLinkServiceInterface
  from '@/app/Services/ExternalLinkService/Contract/ExternalLinkServiceInterface';
import ExternalLinkService from '@/app/Services/ExternalLinkService/ExternalLinkService';
import ChallengeServiceInterface
  from '@/app/Services/ChallengeService/Contract/ChallengeServiceInterface';
import ChallengeService from '@/app/Services/ChallengeService/ChallengeService';
import UserServiceInterface from '@/app/Services/UserService/Contract/UserServiceInterface';
import UserService from '@/app/Services/UserService/UserService';
import LotteryCoinTransactionServiceInterface from '@/app/Services/LotteryCoinTransactionService/Contract/LotteryCoinTransactionServiceInterface';
import LotteryCoinTransactionService from '@/app/Services/LotteryCoinTransactionService/LotteryCoinTransactionService';
import SystemConfigServiceInterface from '@/app/Services/SystemConfig/Contract/SystemConfigServiceInterface';
import SystemConfigService from '@/app/Services/SystemConfig/SystemConfigService';

class ServiceContainer implements ServiceContainerInterface {
  private _axiosClient?: AxiosClientServiceInterface;

  private _apiService?: ApiServiceInterface;

  private _utilsService?: UtilsServiceInterface;

  private _localStorageService?: LocalStorageServiceInterface;

  private _authorizationService?: AuthorizationServiceInterface;

  private _externalLinkService?: ExternalLinkServiceInterface;

  private _challengeService?: ChallengeServiceInterface;

  private _userService?: UserServiceInterface;

  private _lotteryCoinTransactionService?: LotteryCoinTransactionServiceInterface;

  private _systemConfigService?: SystemConfigServiceInterface;

  private _store?: Store<unknown>;

  get apiService(): ApiServiceInterface {
    if (!this._apiService) {
      this._apiService = new ApiService(
        this.axiosClient,
        this.utilsService,
      );
    }

    return this._apiService;
  }

  get utilsService(): UtilsServiceInterface {
    if (!this._utilsService) {
      this._utilsService = new UtilsService();
    }

    return this._utilsService;
  }

  get localStorageService(): LocalStorageServiceInterface {
    if (!this._localStorageService) {
      this._localStorageService = new LocalStorageService(this);
    }

    return this._localStorageService;
  }

  get store(): Store<unknown> {
    if (!this._store) {
      this._store = store(this);
    }
    return this._store;
  }

  get authorizationService(): AuthorizationServiceInterface {
    if (!this._authorizationService) {
      this._authorizationService = new AuthorizationService(
        this.apiService,
        this.localStorageService,
        this.store,
        this.utilsService,
      );
    }

    return this._authorizationService;
  }

  get axiosClient(): AxiosClientServiceInterface {
    if (!this._axiosClient) {
      this._axiosClient = new AxiosClientService(this);

      this._axiosClient.addRequestInterceptors();
      this._axiosClient.addResponseInterceptors();
    }

    return this._axiosClient;
  }

  get externalLinkService(): ExternalLinkServiceInterface {
    if (!this._externalLinkService) {
      this._externalLinkService = new ExternalLinkService();
    }

    return this._externalLinkService;
  }

  get challengeService(): ChallengeServiceInterface {
    if (!this._challengeService) {
      this._challengeService = new ChallengeService(this);
    }

    return this._challengeService;
  }

  get userService(): UserServiceInterface {
    if (!this._userService) {
      this._userService = new UserService(this);
    }

    return this._userService;
  }

  get lotteryCoinTransactionService(): LotteryCoinTransactionServiceInterface {
    if (!this._lotteryCoinTransactionService) {
      this._lotteryCoinTransactionService = new LotteryCoinTransactionService(this);
    }

    return this._lotteryCoinTransactionService;
  }

  get systemConfigService(): SystemConfigServiceInterface {
    if (!this._systemConfigService) {
      this._systemConfigService = new SystemConfigService(this);
    }

    return this._systemConfigService;
  }
}

export default ServiceContainer;
