<template>
  <a-card
    hoverable
    class="preview"
  >
    <template #cover>
      <div
        class="card__text"
      >
        <p
          v-for="(text, index) in data"
          :key="`text-${index}`"
        >
          <b
            v-if="text.weight === 'bold'"
          >
            {{ text.text }}
          </b>
          <template
            v-else
          >
            {{ text.text }}
          </template>
        </p>
      </div>

      <div
        class="card__buttons"
      >
        <a-button
          class="card__button"
          @click="handleEditButton"
        >
          Редактировать
        </a-button>

        <a-button
          class="card__button"
          type="primary"
          @click="handleApplyButton"
        >
          Отправить
        </a-button>
      </div>

      <a-modal
        v-model:visible="state.showModal"
        :centered="true"
        :closable="false"
        title="Твоя заявка создана!"
      >
        <p>
          Поздравляем, твоя заявка на одобрении, после одобрения ты получешь запрошенные жетоны.
          Обычно это происходит быстро, но иногда эльфы поддержки засыпают до утра!
        </p>
        <p>
          Одобрение прилетит в бот.
        </p>

        <template
          #footer
        >
          <a-button
            key="submit"
            type="primary"
            @click="handleModalOk"
          >
            Профиль фрилайнера
          </a-button>
        </template>
      </a-modal>
    </template>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import {
  notification,
} from 'ant-design-vue';

import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';

import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import LotteryCoinTransactionModel from '@/entities/LotteryCoinTransaction/LotteryCoinTransactionModel';
import CalculateCoinsMethodResponseInterface from '@/shared/Api/Contract/Api/RPC/LotteryCoinTransaction/CalculateCoinsMethodResponseInterface';
import UserModel from '@/entities/User/UserModel';
import WordDeclensionHelper from '@/shared/Helpers/WordDeclensionHelper';

export default defineComponent({
  name: 'ChallengeConfirmPage',
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const {
      store,
      localStorageService,
      lotteryCoinTransactionService,
    } = serviceContainer;

    const router = useRouter();

    const state = reactive<{
      values: {
        value: number | null;
        topMember: UserModel | null;
      };
      calculatedData: CalculateCoinsMethodResponseInterface | null;
      showModal: boolean;
    }>({
      values: {
        value: null,
        topMember: null,
      },
      calculatedData: null,
      showModal: false,
    });

    const userInfo = computed<UserInfoResponse>(
      () => store.getters.getUser,
    );

    const data = computed(() => {
      if (state.calculatedData && state.values.topMember) {
        const coinsTextParts = [
          state.calculatedData.additional,
          WordDeclensionHelper.getAdditionalDeclension(state.calculatedData.additional),
          WordDeclensionHelper.getCoinsDeclension(state.calculatedData.additional),
        ];

        const totalTextParts = [
          state.calculatedData.total,
          WordDeclensionHelper.getCoinsDeclension(state.calculatedData.total),
        ];

        return [
          {
            // eslint-disable-next-line max-len, vue/max-len
            text: `${state.values.topMember.fullName} и сильнейшие фрилайнеры твоего клуба увеличили твои шансы на выигрыш на ${state.calculatedData.coefficient}!`,
            weight: 'bold',
          },
          {
            text: `Своими активными действиями они приносят тебе ${coinsTextParts.join(' ')}!`,
          },
          {
            text: `🌟У тебя теперь ${totalTextParts.join(' ')} вместо ${state.values.value}!`,
          },
          {
            text: 'Вдохновляйся ими и жми Отправить 👇',
            weight: 'bold',
          },
        ];
      }
      const stateCoinsTextParts = [
        state.values.value,
        WordDeclensionHelper.getCoinsDeclension(state.values.value ?? 0),
      ];

      return [
        {
          text: `Тебе будет начислено ${stateCoinsTextParts.join(' ')}!`,
        },
        {
          text: 'Если все корректно, жми Отправить👇',
          weight: 'bold',
        },
      ];
    });

    onMounted(async () => {
      const savedValues = localStorageService
        .read<LotteryCoinTransactionModel['lotteryCoinsTransaction']>(LocalStorageKeysEnum.LOTTERY_COIN_TRANSACTION_SAVE_DATA);

      if (savedValues) {
        state.values = savedValues;

        try {
          const lotteryCoinTransaction: LotteryCoinTransactionModel = {
            lotteryCoinsTransaction: {
              value: savedValues.value,
              topMember: savedValues.topMember,
            },
          };

          state.calculatedData = await lotteryCoinTransactionService.calculateCoins(lotteryCoinTransaction);
        } catch {
          throw new Error('Can\'t calculate coins.');
        }
      }
    });

    function handleEditButton() {
      return router.push({ name: 'lotteryCoin' });
    }

    let applyButtonLock = false;

    async function handleApplyButton() {
      if (applyButtonLock) {
        return;
      }

      applyButtonLock = true;

      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if (userInfo.value && state.values.value) {
        try {
          const lotteryCoinTransaction: LotteryCoinTransactionModel = {
            lotteryCoinsTransaction: {
              value: state.values.value,
              topMember: state.values.topMember,
            },
          };

          await lotteryCoinTransactionService.create(lotteryCoinTransaction);

          const notificationMessage = {
            message: 'Твоя заявка успешно создана!',
            description: 'Твоя заявка принята, после одобрения тебе будут начислены жетоны!',
            duration: 30,
          };

          notification.success(notificationMessage);
        } catch {
          const errorNotificationMessage = {
            message: 'Что-то пошло не так!',
            description: 'Твоя заявка не была создана! Повтори попытку позже.',
            duration: 30,
          };

          notification.error(errorNotificationMessage);
        }
      }

      router.push({ name: 'home' });
    }

    function handleModalOk() {
      state.showModal = false;

      router.push({ name: 'home' });
    }

    return {
      state,
      data,
      handleEditButton,
      handleApplyButton,
      handleModalOk,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>

</style>
