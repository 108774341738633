<template>
  <a-card
    hoverable
    class="card"
  >
    <template #cover>
      <div
        class="card__text"
      >
        <template
          v-for="(text, index) in activeOption.textLines"
          :key="`textLine${index}`"
        >
          <p>
            <b
              v-if="text.weight === 'bold'"
            >
              {{ text.text }}
            </b>
            <template
              v-else
            >
              {{ text.text }}
            </template>
          </p>
          <ul
            v-if="text.list"
            class="card__list"
          >
            {{ text }}
            <li
              v-for="(listItem, listIndex) in text.list"
              :key="`listItem${index}${listIndex}`"
            >
              <b
                v-if="text.weight === 'bold'"
              >
                {{ listItem }}
              </b>
              <template
                v-else
              >
                {{ listItem }}
              </template>
            </li>
          </ul>
        </template>
      </div>
      <div
        v-if="activeOption.field && activeOption.valueType === 'input'"
        class="card__input"
      >
        <a-input
          :maxLength="activeOption.maxLength"
          :value="state.values[activeOption.field]"
          @input="handleInputChange($event, activeOption.field)"
        />
      </div>
      <div
        v-if="activeOption.field && activeOption.valueType === 'select'"
        class="card__input"
      >
        <a-select
          v-if="activeOption.field === 'topMember' && topUserSelectOptions && topUserSelectOptions.length > 0"
          :model-value="getSelectValue(activeOption.field)"
          :options="topUserSelectOptions"
          style="width: 100%"
          @change="handleSelectChange($event, activeOption.field)"
        />
      </div>
      <div
        class="card__buttons"
      >
        <a-button
          v-if="activeOption.buttonText"
          type="primary"
          block
          :disabled="isForwardDisabled"
          @click="handleButtonClick"
        >
          {{ activeOption.buttonText }}
        </a-button>
        <a-button
          v-if="showApplyButton"
          type="primary"
          block
          :disabled="isApplyDisabled"
          @click="handleApplyButton"
        >
          ДАЛЬШЕ!
        </a-button>
      </div>
    </template>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  computed, defineComponent, inject, onMounted, reactive, ref,
} from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useRouter } from 'vue-router';
import { SelectProps } from 'ant-design-vue';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';

import UserModel from '@/entities/User/UserModel';

interface State {
  values: {
    value: number | null;
    topMember: UserModel | null;
  };
  availableCoins: {
    min: number;
    max: number;
  };
  clubTopList: Array<UserModel>
}

type TextLine = {
  text: string,
  mb?: string,
  list?: Array<string>,
  weight?: string,
};

export default defineComponent({
  name: 'LotteryCoinRequestPage',

  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const router = useRouter();

    const state = reactive<State>({
      values: {
        value: 10,
        topMember: null,
      },
      availableCoins: {
        min: 10,
        max: 1000,
      },
      clubTopList: [],
    });

    interface StepInterface {
      image?: string,
      textLines: TextLine[],
      valueType?: 'buttonGroup' | 'input' | 'select',
      maxLength?: number,
      field?: 'value' | 'topMember',
      buttonText?: string,
    }

    const options = computed<Array<StepInterface>>(() => {
      const selectFreelinerText: TextLine[] = [
        {
          text: 'Выбери фрилайнера, который вдохновил тебя в этом месяце. ',
          weight: 'bold',
        },
        {
          text: 'В выпадающем списке — фрилайнеры, которые входят в топ-10 сильнейших в твоем клубе по итогам месяца.',
        },
        {
          text: 'Выбери фрилайнера из списка и нажми Дальше 👇',
          weight: 'bold',
        },
      ];
      const noTopFreelinersText = [
        {
          text: 'В твоем клубе нет фрилайнера, входящего в топ - 10 перстневого рейтинга.',
        },
        {
          text: 'Сильнейшим фрилайнером в следующем месяце можешь стать ты!',
        },
        {
          text: 'Жми Дальше 👇',
          weight: 'bold',
        },
      ];

      const textLineTopUser = state.clubTopList.length > 0 ? selectFreelinerText : noTopFreelinersText;

      return [
        {
          textLines: [
            {
              text: 'Напиши количество жетонов, которое хочешь обменять (1 жетон = 100 YOU).',
              weight: 'bold',
            },
            {
              text: 'Если ты Premium, 100 жетонов будут учтены автоматически.',
            },
            {
              text: 'Если ты превысишь баланс, кнопка Готово станет неактивной.',
            },
            {
              text: 'Напиши количество жетонов и нажми Дальше 👇',
              weight: 'bold',
            },
          ],
          valueType: 'input',
          maxLength: 30,
          field: 'value',
          buttonText: 'ДАЛЬШЕ!',
        },
        {
          textLines: textLineTopUser,
          valueType: 'select',
          field: 'topMember',
        },
      ];
    });

    const activeOptionIndex = ref(0);

    const activeOption = computed(() => options.value[activeOptionIndex.value]);

    const isBackDisabled = computed(() => activeOptionIndex.value === 0);

    const isForwardDisabled = computed(() => {
      if (!activeOption.value.field) {
        return false;
      }

      if (activeOption.value.field === 'value' && state.values[activeOption.value.field]) {
        const selectedAmount = state.values[activeOption.value.field] ?? 0;

        return selectedAmount < state.availableCoins.min || selectedAmount > state.availableCoins.max;
      }

      return !state.values[activeOption.value.field];
    });

    const isApplyDisabled = computed(() => {
      if (state.clubTopList.length > 0) {
        return !state.values.topMember;
      }

      return false;
    });

    const showApplyButton = computed(
      () => activeOptionIndex.value === (options.value.length - 1),
    );

    const topUserSelectOptions = computed<SelectProps['options']>(() => state.clubTopList.map((user: UserModel) => ({
      value: user.id,
      label: user.fullName,
    })) ?? []);

    function getSelectValue(field: 'topMember') {
      const userId = state.values[field]?.id;

      if (userId && topUserSelectOptions.value) {
        return topUserSelectOptions.value.find((option) => option.value === userId);
      }

      return null;
    }

    function handleButtonClick() {
      if (activeOptionIndex.value + 1 > options.value.length) {
        return;
      }

      activeOptionIndex.value += 1;

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function handleInputChange(
      event: InputEvent,
      field: 'value',
    ) {
      const { target } = event;

      if (target instanceof HTMLInputElement) {
        const value = target?.value ?? '';

        state.values[field] = value === '' ? null : Number(value);
      }
    }

    async function handleApplyButton() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if (!state.values.value) {
        throw new Error('Empty value!');
      }

      const json = JSON.stringify(state.values);

      serviceContainer?.localStorageService.write(LocalStorageKeysEnum.LOTTERY_COIN_TRANSACTION_SAVE_DATA, json);

      router.push({ name: 'lotteryCoinConfirm' });
    }

    function handleTopUserClick(
      value: UserModel,
      field: 'topMember',
    ) {
      state.values[field] = value;

      handleApplyButton();
    }

    function handleSelectChange(value: string | number, field: 'topMember') {
      const user = state.clubTopList.find((user: UserModel) => user.id === value);

      if (!user) {
        throw new Error(`Can't find user with id = ${value}`);
      }

      state.values[field] = user;
    }

    onMounted(async () => {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      await serviceContainer.store.dispatch('updateUser');

      try {
        state.clubTopList = await serviceContainer.userService.listClubTop();
      } catch {
        throw new Error('Can\'t get top user list');
      }

      try {
        state.availableCoins = await serviceContainer.lotteryCoinTransactionService.getAvailableCoins();
      } catch {
        throw new Error('Can\'t get available coint amount');
      }
    });

    return {
      activeOption,
      isBackDisabled,
      isForwardDisabled,
      isApplyDisabled,
      handleButtonClick,
      state,
      topUserSelectOptions,
      getSelectValue,
      showApplyButton,
      handleInputChange,
      handleTopUserClick,
      handleSelectChange,
      handleApplyButton,
    };
  },
});
</script>

<style
  lang="scss"
>
.card {
  width: 300px;
  margin: 0 auto;

  &__text {
    padding: 10px;
  }

  &__buttons {
    display: flex !important;
    width: 100%;
    padding: 10px;
    gap: 8px;
  }

  &__button {
    flex: 1 1 auto;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__input {
    padding: 10px;
  }

  &__select {
    width: 100%;
  }

  &__button-group {
    display: flex;
    gap: 8px;

    &_column {
      flex-direction: column;
    }
  }
}
</style>
